import React from "react";
import { graphql } from "gatsby";
import Footer from "../components/Footer";
import Layout from "../components/Layout";
import Hero from "../components/Hero";
import Nav from "../components/nav";
import VideoCard from "../components/VideoCard";
import useHubspotForm from '@aaronhayes/react-use-hubspot-form';

const ReplaysPage = ({ data: { prismicReplays } }) => {
  const { data } = prismicReplays;

  const { loaded, error, formCreated } = useHubspotForm({
    portalId: '8056686',
    formId: '6f72f6a4-d4d0-4ef8-b92e-8368112c35bb',
    target: '#hb-form-replay'
});

  return (
    <Layout
      title={data.page_title.text}
      description={data.page_description.text}
      thumbnail={data.share_preview.url}
      bg="gray-100">
      <Nav></Nav>
      <Hero
        title={data.replays_title.text}
        description={data.replays_description.raw}
      />
      {data.videos?.map((v, i) => (
        <VideoCard
          title={v.video_title.text}
          desc={v.video_description.raw}
          label={v.video_label.text}
          video={v.video_url.url}
          key={i}
          slug={v.slug}
        />
      ))}

      <div className="py-12 bg-gray-900 " id="form">
        <div className="  mx-auto max-w-screen-lg ">
          <p className="py-8 mt-2 text-3xl leading-9 font-extrabold text-white sm:text-4xl sm:leading-10 lg:text-5xl lg:leading-none text-center">
            Téléchargez nos présentations !
          </p>

          <div className=" bg-white rounded shadow-lg p-4 lg:p-16">
            <div id="hb-form-replay"></div>
           
          </div>
        </div>
      </div>
      <Footer title={"Les Ops dans une nouvelle dimension"} />
    </Layout>
  );
};

export default ReplaysPage;

export const pageQuery = graphql`
  query ReplaysQuery {
    prismicReplays {
      data {
        page_title {
          text
        }
        page_description {
          text
        }
        share_preview {
          url
        }
        replays_description {
          raw
        }
        replays_title {
          text
        }
        videos {
          video_description {
            raw
          }
          video_label {
            text
          }
          video_title {
            text
          }
          video_url {
            url
          }
          slug
        }
      }
    }
  }
`;
