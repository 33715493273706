import React from "react";
import Img from "gatsby-image";
import { RichText } from "prismic-reactjs";
import { useClipboard } from "use-clipboard-copy";

const VideoCard = (props) => {
  const clipboard = useClipboard({
    copiedTimeout: 1000, // timeout duration in milliseconds
  });

  return (
    <div
      className="columns flex flex-wrap items-center max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8 features mb-8 bg-white rounded shadow-lg"
      id={props.slug}>
      <div className="w-full md:w-1/2 py-8 px-6 flex flex-col justify-center   ">
        <div
          className="flex items-center font-bold  text-primary-900 label"
          data-sal="slide-up"
          data-sal-delay="300"
          data-sal-easing="ease-in-out"
          data-sal-duration="500">
          {props.label}
          <button
            onClick={() => clipboard.copy(window.location.origin + window.location.pathname + '#' + props.slug)}
            className="text-gray-700 p-2 ml-2 bg-gray-200 hover:text-primary-900 cursor-pointer rounded flex justify-center items-center">
             {clipboard.copied ? 'Copié' : 
            <svg
              width="32"
              height="32"
              viewBox="0 0 36 36"
              className="flex-shrink-0 h-5 w-5 "
              fill="currentColor"
              stroke="currentColor">
              <g
                id="Icon_feather-copy"
                data-name="Icon feather-copy"
                transform="translate(-1.5 -1.5)">
                <path
                  id="Tracé_1"
                  data-name="Tracé 1"
                  d="M16.5,13.5H30a3,3,0,0,1,3,3V30a3,3,0,0,1-3,3H16.5a3,3,0,0,1-3-3V16.5A3,3,0,0,1,16.5,13.5Z"
                  fill="none"
                  stroke="#000"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="3"
                />
                <path
                  id="Tracé_2"
                  data-name="Tracé 2"
                  d="M7.5,22.5H6a3,3,0,0,1-3-3V6A3,3,0,0,1,6,3H19.5a3,3,0,0,1,3,3V7.5"
                  fill="none"
                  stroke="#000"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="3"
                />
              </g>
            </svg>}
          </button>
        </div>
        <h2
          className="mt-6 text-3xl leading-9 font-extrabold text-gray-900 sm:text-4xl sm:leading-10 "
          data-sal="slide-up"
          data-sal-delay="300"
          data-sal-easing="ease-in-out"
          data-sal-duration="500">
          {props.title}
        </h2>
        <div
          className="mt-6 text-lg leading-7 text-gray-500 richtext"
          data-sal="slide-up"
          data-sal-delay="300"
          data-sal-easing="ease-in-out"
          data-sal-duration="500">
          <RichText render={props.desc} />
        </div>

          <div
            className="flex items-center font-bold text-primary-900 underline mt-2"
            data-sal="slide-up"
            data-sal-delay="300"
            data-sal-easing="ease-in-out"
            data-sal-duration="500">
            <a href="#form">Téléchargez la présentation PDF !</a>
          </div>

      </div>

      <div className="w-full md:w-1/2 p-6 md:block text-center" data-sal="fade">
        {props.img && (
          <Img fluid={props.img.fluid} alt="feature image zenops" />
        )}
        {props.video && (
          <iframe
            src={props.video}
            frameBorder="0"
            width="100%"
            height="400"
            allow="autoplay; fullscreen"
            allowFullScreen
            className="mx-auto max-w-screen-xl "></iframe>
        )}
      </div>
    </div>
  );
};

export default VideoCard;
